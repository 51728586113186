import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import SpeedIcon from "../../svg/speed.svg";
import SecurityIcon from "../../svg/security.svg";
import SchickIcon from "../../svg/schick.svg";


export default function WebsiteEntwicklungHeader() {
    return (
        <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
                <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
                    <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
                        <div>
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl">
                                Ultra-schnelle Webseiten
                            </h1>
                            <p className="mt-4 text-gray-500">
                                Webseiten können heutzutage nicht schnell genug sein, wenn Sie nicht von Suchmaschinen
                                abgestraft werden wollen.
                                Ich baue Ihnen Webseiten, die unfassbar schnell sind — so wie diese hier!
                            </p>
                        </div>
                        <div className="aspect-w-3 aspect-h-2 bg-gray-100 rounded-lg overflow-hidden">
                            <StaticImage
                                src="../../images/speed.jpg"
                                formats={["auto", "webp", "avif"]}
                                alt="Ultra-schnelle Websiten"
                                width={490}
                                loading="eager"
                                className="object-center object-cover"
                            />
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">

                        <div key="Speed" className="sm:flex lg:block">
                            <div className="sm:flex-shrink-0">
                                <SpeedIcon className="w-16 h-16"/>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                <h3 className="text-sm font-medium text-gray-900">Ultra-schnell</h3>
                                <p className="mt-2 text-sm text-gray-500">Um schnelle Webseiten zu bauen, muss einiges
                                    beachtet werden. Überlassen Sie das uns und konzentrieren Sie sich auf die
                                    Inhalte.</p>
                            </div>
                        </div>

                        <div key="Sicher" className="sm:flex lg:block">
                            <div className="sm:flex-shrink-0">
                                <SecurityIcon className="w-16 h-16"/>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                <h3 className="text-sm font-medium text-gray-900">Unglaublich sicher</h3>
                                <p className="mt-2 text-sm text-gray-500">Unsere Webseiten sind praktisch Flat-Files
                                    ohne Datenbanken und dergleichen. Dazu hosten wir sie auf einem globalen CDN. Das
                                    eliminiert praktisch das Risiko von DDoS-Angriffen.</p>
                            </div>
                        </div>

                        <div key="Schick" className="sm:flex lg:block">
                            <div className="sm:flex-shrink-0">
                                <SchickIcon className="w-16 h-16"/>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                <h3 className="text-sm font-medium text-gray-900">Einfach nur schick</h3>
                                <p className="mt-2 text-sm text-gray-500">Static sites müssen nicht langweilig aussehen.
                                    Im Gegenteil! Wir bauen Ihnen optisch äußerst ansprechende Webseiten. So wie diese
                                    hier!</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
