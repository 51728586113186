import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";
import WebsiteEntwicklungHeader from "../../components/UeberMich/WebsiteEntwicklungHeader";
import Teaser1 from "../../components/UeberMich/Teaser1";
import WebsiteVorteile from "../../components/UeberMich/WebsiteVorteile";


export default function HighSpeedWebsites() {
    const Breadcrumbs = [
        { name: 'Ueber mich', href: '#', current: false },
        { name: 'High-Speed-Websites', href: '/UeberMich/HighSpeedWebsites', current: true },
    ]

    return (
        <>
            <Layout>
                <Seo title="Delphi-Entwicklung"/>
                <BreadCrumbs pages={Breadcrumbs}/>

                <WebsiteEntwicklungHeader />

                <Teaser1 headline1="Lust auf eine ultra-schnelle Website bekommen?" headline2="Lassen Sie uns möglichst bald über Ihr Vorhaben sprechen!" buttonText="Kontakt" url="/Kontakt"/>

                <WebsiteVorteile />


            </ Layout>
        </>
    )
}