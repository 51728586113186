import * as React from "react"
import {
    BookOpenIcon,
    EmojiHappyIcon,
    PhotographIcon,
    LightBulbIcon,
    GlobeAltIcon,
    ExternalLinkIcon
} from '@heroicons/react/outline'

const features = [
    { name: 'Statische Seiten', icon: BookOpenIcon, description: 'Wir berechnen die Seiten vorab, damit keine Zeit beim Anzeigen verschwendet wird.' },
    { name: 'Passende Bilder', icon: PhotographIcon, description: 'Bilder werden in verschiedenen Auflösungen vorberechnet, damit die kleinste passende Größe geladen wird.' },
    { name: 'Moderne Bildformate', icon: LightBulbIcon, description: 'Wir bieten die modernsten Bildformate an, wie webp und avif, damit das Maximum herausgeholt wird. ' },
    { name: 'CDN Hosting', icon: GlobeAltIcon, description: 'Static Website brauchen keinen Schnick-Schnack und können daher auf ultra-schnellen CDNs gehostet werden.' },
    { name: 'Lazy Loading', icon: ExternalLinkIcon, description: 'Wir laden Bilder erst dann, wenn sie auch tatsächlich im sichtbaren Bereich sind.' },
    { name: 'SEO optimiert', icon: EmojiHappyIcon, description: 'Technisch geben wir alles, damit Suchmaschinen happy sind. Selbst auf ausreichend Kontraste achten wir.' },
]

export default function WebsiteVorteile() {
    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-diit uppercase">Unabdingbar für erfolgreiche Websites</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Diese Technologien setzen wir für Sie ein
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    Hier sehen Sie einen kleinen Auszug aus den Techniken, die wir einsetzen, damit unsere Websiten so schnell werden.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                      <span className="inline-flex items-center justify-center p-3 bg-diit rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            {feature.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
